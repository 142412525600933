import { responseHandler } from "./responseHandler"
const { STAGE, AOMORI_URL } = process.env

export const fetchAndSaveAuthToken = ({ liffId, userId }) => {
  const url = `/api/token`
  return fetch(url, {
    method: "GET",
    headers: {
      "X-liff-Id": liffId,
      "x-lineuser-id": userId,
    },
  })
    .then(responseHandler)
    .then(res => {
      return res.token
    })
}

export const getUserInfo = () => {
  const url = `/api/user`
  return fetch(url, {
    method: "GET",
  }).then(responseHandler)
}

export const getPasscode = ({ phone }) => {
  const url = `/api/phone/passcode`
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ phone }),
  }).then(responseHandler)
}

export const validatePasscode = ({
  phone: rawPhone,
  passcode,
  lineVerify = false,
}) => {
  const phone = rawPhone.replace(/\+886/g, "0")
  const url = `/api/phone/valid`
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      phone,
      passcode,
      lineVerify,
    }),
  }).then(responseHandler)
}

export const createSelf = ({ name, idNum, birth, idType, gender }) => {
  const url = `/api/self`
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      name,
      idType,
      gender,
      IdNum: idNum,
      Birth: birth,
    }),
  }).then(responseHandler)
}

export const updatePatient = ({
  id,
  name,
  birth,
  idNum,
  idType,
  gender,
  note,
  relation: alias,
}) => {
  const url = `/api/patient/${id}`
  const body = { name, birth, idType, gender }
  if (alias) {
    body.alias = alias
  }
  if (idNum) {
    body.idNum = idNum
  }
  if (note) {
    body.note = note
  }
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
  }).then(responseHandler)
}

export const listMembers = async () => {
  try {
    const user = await getUserInfo()
    return {
      bindingPhoneNumber: user.Phone,
      members: user.patients
        ? user.patients
            .filter(p => !p.isSelf)
            .map(p => {
              const { id, name, idNum, birth, alias: relation } = p
              return {
                ...p,
                id: `${id}`,
                name,
                idNum,
                birth,
                relation,
              }
            })
        : [],
    }
  } catch (error) {
    throw error
  }
}

export const createMember = ({
  name,
  relation,
  idNum,
  idType,
  gender,
  birth,
}) => {
  const url = `/api/patient`
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      name,
      idType,
      IdNum: idNum,
      Birth: birth,
      alias: relation,
      gender,
    }),
  }).then(responseHandler)
}

export const deleteMember = ({ id }) => {
  const url = `/api/patient/${id}`
  return fetch(url, {
    method: "DELETE",
  }).then(responseHandler)
}
