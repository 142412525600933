import React, { useEffect, useState } from "react"
import { LineContext } from "components/context"
import AppointmentsPage from "pages/appointments"
import { setupLiff } from "src/lib/liff"
import { fetchAndSaveAuthToken } from "services/user.service"

export default function Home(props) {
  const [message, setMessage] = useState({})
  const [token, setToken] = useState()
  useEffect(() => {
    setupLiff()
      .then(async res => {
        setMessage(res)
        const {
          userId = "U30fb0f6e38f7378459a467cac2a2da5e",
          liffId = "1655100132-NerKp8jj",
        } = res
        const t = await fetchAndSaveAuthToken({ userId, liffId })
        setToken(t)
      })
      .catch(async err => {
        setMessage(err)
        // const userId = "U30fb0f6e38f7378459a467cac2a2da5e"
        // const liffId = "1655100132-NerKp8jj"
        // const t = await fetchAndSaveAuthToken({ userId, liffId })
        // setToken(t)
      })
  }, [])
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <AppointmentsPage {...props} {...message}></AppointmentsPage>
      </LineContext.Provider>
    )
  }
  return null
}
